@import "settings";

h1 {
	font-size:2em;
}



.container {
	@include clearfix;
}

.post {
	padding:8px;
	&.post-shadow {
		.image {
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				right:0;
				top:0;
				background: rgb(0,0,0);
				background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 60%);
			}
		}
	}
	&.post-big {
		.image {
			padding-bottom: 30%;
			.post-info {
				bottom:20px;
				left:20px;
				right:20px;
				.post-title {
					font-size:24px;
				}
				.post-text {
					font-size:15px;
					max-width:550px;
				}
			}
		}
	}
	span {
		display:block;
	}
	a:hover {
		.image {
			.img-resize {
				transform:scale(1.05);
			}
		}
		> span.post-title {
			color:$blue-color;
		}
	}
	.image {
		position:relative;
		height:0;
		padding-bottom: 56.3%;
		overflow: hidden;
		.post-category {
			position:absolute;
			z-index:2;
			top:0;
			left:0;
			padding:7px;
			font-family: $exo;
			color:#fff;
			font-weight:700;
			text-transform: uppercase;
			font-size:12px;
		}
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			overflow: hidden;
			transition:all 0.3s ease-in-out;
			background:$blue-color url(../images/placeholder.svg) no-repeat center center;
			background-size:160px auto;
			img {
				max-width:none;
			}
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
			}
		}
		.post-info {
			position:absolute;
			z-index:2;
			bottom:10px;
			left:10px;
			right:10px;
		}
		.post-title {
			font-family: $exo;
			color:#fff;
			font-size: 18px;
			font-weight:700;
		}
		.post-text {
			padding-top: 10px;
			font-size:14px;
			color:#fff;
		}
	}
	.post-title {
		font-family: $exo;
		color:$darker-font-color;
		font-size: 16px;
		font-weight:700;
		padding-top: 10px;
		transition:all 0.3s;
	}
}

header {
	padding:30px 0;
	.left {
		float:left;
		padding-left: 30px;
		a.logo {
			display:block;
			background:url(../logo.svg) no-repeat center center;
			width:250px;
			height:68px;
		}
	}
	.right {
		padding-top: 30px;
		float:right;
		nav {
			float:left;
			ul {
				margin:0;
				padding:0;
				li {
					display:inline-block;
					text-transform: uppercase;
					font-family: $exo;
					font-weight:700;
					position:relative;
					&:last-child:after {
						display:none;
					}
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:0;
						bottom:0;
						right:0;
						width:1px;
						background-color:$link-color;
					}
					a {
						color:$darker-font-color;
						display:block;
						padding:0 20px;
						&:hover, &.active {
							color:$link-color;
						}
					}
				}
			}
		}
		.search-trigger {
			float:left;
			width:24px;
			height:24px;
			background:url(../images/search.svg) no-repeat center center;
			background-size:contain;
			cursor:pointer;
			transition:all 0.3s;
			margin-left: 50px;
			&:hover {
				filter: invert(64%) sepia(30%) saturate(618%) hue-rotate(43deg) brightness(99%) contrast(90%);
			}
		}
	}
}


.main-container {
	overflow: hidden;
	.align-site {
		display:flex;
		flex-wrap:wrap;
		.sidebar {
			flex: 0 0 355px;
			padding-right: 50px;
			position:relative;
			z-index:2;
			.sidebar-content {
				background:#eff5f7;
				height:100%;
				padding:30px;
				h2 {
					margin:0;
					padding:0;
					text-transform: uppercase;
				}
				nav {
					padding-top: 15px;
					ul {
						margin:0;
						padding:0;
						list-style: none;
						li {
							font-family: $exo;
							font-weight:700;
							text-transform: uppercase;
							position:relative;
							&:hover, &.active {
								a {
									color:$blue-color;
									transform:translateX(15px);
								}
								&:after {
									opacity:1;
								}
							}
							&:after {
								content:"";
								display:block;
								position:absolute;
								top:0;
								bottom:0;
								left:0;
								width:10px;
								background:url(../images/arrow.svg) no-repeat center center;
								background-size:contain;
								transition:all 0.4s ease;
								opacity:0;
							}

							a {
								display:block;
								padding:7px 0;
								color:$darker-font-color;
								transition:all 0.3s ease;
							}
						}
					}
				}
			}
		}
		.main-content {
			flex:1;
			width: -webkit-calc(100% - 3550px);
			width: -moz-calc(100% - 3550px);
			width: calc(100% - 3550px);
		}
	}
}

.featured-posts {
	overflow: hidden;
	.container {
		margin:-8px;
	}
	.video {
		float:left;
		width:67%;
		padding:8px;
		.video-content {
			height:0;
			padding-bottom: 56.25%;
			position:relative;
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				border:none;
				box-shadow:none;
				width:100%;
				height:100%;
			}
		}
	}
	.right {
		float:right;
		width:33%;
	}
}

.post-carousel-wrapper {
	background:#eff5f7;
	position:relative;
	margin:8px 0;
	padding:15px 0;
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		bottom:0;
		left:100%;
		width:90000px;
		background:#eff5f7;
	}
	&:before {
		content:"";
		display:block;
		position:absolute;
		top:0;
		bottom:0;
		right:100%;
		width:30px;
		background:#eff5f7;
	}
	.container {
		margin:0 -8px;
	}
	.post-carousel {
		margin:0;
		.slick-dots {
			position:static;
			padding-top: 15px;
			li {
				width:12px;
				height:12px;
				border-radius:100%;
				border:1px solid #9c9c9c;
				&.slick-active {
					background:#9c9c9c;
				}
				button {
					display:none !important;
				}
			}
		}
	}
}

.monthly-featured {
	overflow: hidden;
	h2 {
		text-transform: uppercase;
		margin-left: 8px;
		margin-top:30px;
		position:relative;
		display:inline-block;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:50%;
			left:105%;
			width:90000px;
			height:1px;
			background:$font-color;
		}
	}
	.featured-big {
		float:left;
		width:38%;
		.post {
			.post-title {
				font-size:18px;
			}
		}
	}
	.right {
		float:left;
		width:62%;
		display:flex;
		flex-wrap:wrap;
		.post {
			float:left;
			width:33.33333%;
			margin-bottom: 20px;
			.post-title {
				font-size:14px !important;
			}
		}
	}
}

.seperator {
	height:1px;
	background:$darker-font-color;
	margin:30px 0;
	@include clearfix;
	clear:both;
}

.post-list {
	.container {
		display:flex;
		flex-wrap:wrap;
	}
	.post {
		width:25%;
		&.post-big {
			width:100%;
		}
	}
}

.media-corner {
	background:#eff5f7;
	position:relative;
	padding-bottom: 20px;
	h2 {
		text-transform: uppercase;
		margin-left: 8px;
		margin-top:30px;
		position:relative;
		display:inline-block;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:50%;
			left:105%;
			width:90000px;
			height:1px;
			background:$font-color;
		}
	}
	a.btn {
		position:absolute;
		top:30px;
		right:0;
		color:#fff;
		font-family: $exo;
		font-weight:700;
		font-size:12px;
		text-transform: uppercase;
		padding:8px;
		background:$darker-font-color;
		transition:all 0.3s;
		&:hover {
			background:darken($darker-font-color, 10%);
		}
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		bottom:0;
		left:100%;
		width:90000px;
		background:#eff5f7;
	}
	&:before {
		content:"";
		display:block;
		position:absolute;
		top:0;
		bottom:0;
		right:100%;
		width:30px;
		background:#eff5f7;
	}
	.container {
		margin:0 -8px;


		.video {
			float:left;
			width:72%;
			padding:8px;
			.post {
				padding:0;
				.post-title {
					font-size:24px;
				}
				p {
					max-width:550px;
				}
			}
			.video-content {
				height:0;
				padding-bottom: 56.25%;
				position:relative;
				iframe {
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					border:none;
					box-shadow:none;
					width:100%;
					height:100%;
				}
			}
		}
		.right {
			float:right;
			width:28%;
		}

	}
}


.ask-advice {
	margin-top: 50px;
	.banner-wrapper {
		padding-top: 40px;
		position:relative;
		color:#fff;
		h1,h2,h3,h4 {
			color:#fff;
			text-transform: uppercase;
			margin:0;
			padding:0;
		}
		p {
			margin:0;
			padding:0;
		}
		.banner {
			padding:100px 20px 20px 20px;
			background:url(../images/banner.jpg) no-repeat center center;
			background-size:cover;
		}
		.banner-graphic {
			position:absolute;
			top:0;
			right:50px;
			background:url(../images/graphic.png) no-repeat center center;
			background-size:contain;
			width:280px;
			height:230px;
		}
	}
}

footer {
	.top, .bottom {
		@include clearfix;
	}
	.top {
		border-top: 1px solid $font-color;
		padding-top: 10px;
		margin-top: 30px;
	}
	.left {
		float:left;
	}
	.right {
		float:right;
		ul {
			margin:0;
			padding:0;
			li {
				display:inline-block;
				&:after {
					content:"|";
					margin:0 5px;
				}
				&:last-child {
					&:after {
						display:none;
					}
				}
				a {
					color:$font-color;
					&:hover {
						color:$link-color;
					}
				}
			}
		}
	}
	.bottom {
		padding:30px 0;
		.left {
			img {
				width:250px;
			}
		}
		.right {
			max-height:100px;
			img {
				display:inline-block;
				margin-left: 20px;
			}
		}
	}
}




.scroll-up {
	background:$link-color;
	position:fixed;
	bottom:0;
	right:0;
	z-index:2;
	width:45px;
	height:45px;
	cursor:pointer;
	transition:all 0.3s ease;
	opacity:0;
	visibility: hidden;
	&.active {
		opacity: 1;
		visibility: visible;
	}
	&:hover {
		&:after {
			opacity:0.6;
		}
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:url(../images/arrow.svg) no-repeat center center;
		background-size:30%;
		transform:rotate(-90deg);
		transition:all 0.3s;
		filter: saturate(0%) brightness(500%);
		opacity:1;
	}
}

.subpage {
	font-size:16px;

	img.float-left {
		float:left;
		margin:0 30px 20px 0;
		max-width:60%;
	}

	form {
		max-width:500px;
	}
	input[type="text"], input[type="email"], input[type="password"], input[type="submit"], textarea {
		background-color:#fff;
		border:1px solid #ccc;
		display:block;
		padding:14px;
		font-size:16px;
		width:100%;
		color:$font-color;
		margin-top:4px;
		transition:border-color 0.3s;
		&:focus {
			border-color:$link-color;
		}
	}
	textarea {
		width:100%;
		max-width:100%;
		max-height:800px;
		min-height:150px;
	}
	input[type="submit"] {
		margin-top: 20px;
		background-color:$blue-color;
		color:#fff;
		font-family: $exo;
		font-weight:700;
		border:none;
		transition:background-color 0.3s;
		cursor:pointer;
		&:hover {
			background-color:$link-color;
		}
	}
}

@media only screen and (max-width:1400px) {
	.featured-posts {
		.video {
			width:100%;
		}
		.right {
			width:100%;
			.post {
				width:50%;
				float:left;
			}
		}
	}

	.monthly-featured {
		.right {
			.post {
				width:50%;
			}
		}
	}

	.post-list {
		.post {
			width:33.3333%;
		}
	}

	footer {
		.top {
			.right {
				width:100%;
				margin-top: 20px;
			}
		}

		.bottom {
			.left {
				display:none;
			}
			.right {
				float:left;
				width:100%;
				img {
					margin-left: 0;
					margin-right: 30px;
				}
			}
		}
	}
}

@media only screen and (max-width:1200px) {
	.monthly-featured {
		.featured-big {
			width:100%;
		}
		.right {
			width:100%;
			.post {
				width:50%;
			}
		}
	}

	.post {
		&.post-big {
			.image {
				padding-bottom: 62.5%;
			}
		}
	}

	.post-list {
		.post {
			width:50%;
		}
	}

	.media-corner {
		.container {
			.video {
				width:100%;
			}
			.right {
				width:100%;
				display:flex;
				flex-wrap:wrap;
				.post {
					width:33.333%;
				}
			}
		}
	}

	.ask-advice {
		margin-top: 0;
		.banner-wrapper {
			.banner {
				padding-top: 50px;
			}
			.banner-graphic {
				display:none;
			}
		}
	}
}

@media only screen and (max-width:1000px) {
	header {
		padding:10px 0;
		.right {
			padding-top: 20px;
			nav {
				position:absolute;
				top:87px;
				left:0;
				right:0;
				z-index:5;
				background:#fff;
				opacity:0;
				visibility: hidden;
				transition:all 0.3s;
				&.active {
					opacity:1;
					visibility: visible;
				}
				ul {
					padding:30px 0;
					li {
						display:block;
						text-align: center;
						&:after {
							display:none;
						}
						a {
							padding:10px;
						}
					}
				}
			}
			.search-trigger {
				margin:0 10px 0 0;
			}
			.responsive-nav {
				display:block;
				margin-top: -6px;
			}
		}
	}


	.main-container {
		.align-site {
			display:block;
			.sidebar {
				flex:none;
				padding-right: 0;
				margin-bottom: 16px;
				.sidebar-content {
					position:relative;
					.sidebar-toggle {
						position:absolute;
						top:32px;
						right:32px;
						background:$blue-color;
						width:25px;
						height:25px;
						cursor:pointer;
						&.active {
							&:after {
								transform:rotate(-90deg);
							}
						}
						&:after {
							content:"";
							display:block;
							position:absolute;
							top:0;
							left:0;
							right:0;
							bottom:0;
							background:url(../images/arrow.svg) no-repeat center center;
							background-size:30%;
							transform:rotate(90deg);
							transition:all 0.3s;
							filter: saturate(0%) brightness(500%);
							opacity:1;
						}
					}
					nav {
						display:none;
					}
				}
			}
			.main-content {
				width:100%;
			}
		}
	}
	.post-carousel-wrapper {
		&:before {
			width:50000px;
		}
	}
	.media-corner {
		&:before {
			width:5000px;
		}
	}
}

@media only screen and (max-width:800px) {
	.pageAlign {
		padding:0 15px;
	}

	.post {
		&.post-big {
			.image {
				.post-info {
					.post-title {
						font-size:16px;
					}
				}
			}
		}
		.post-title {
			font-size:14px;
		}
		p {
			font-size:13px;
		}
		.image {
			.post-title {
				font-size:14px;
			}
			.post-text {
				font-size:13px;
			}
		}
	}

	.media-corner {
		.container {
			.video {
				.post {
					.post-title {
						font-size:16px;
					}
				}
			}
		}
	}
	.subpage {
		img.float-left {
			margin:0 0 20px 0;
			max-width:100%;
		}
	}
}

@media only screen and (max-width:600px) {
	header {
		.left {
			padding-left: 0;
			a.logo {
				width:180px;
				height:48px;
			}
		}
		.right {
			padding-top: 11px;
			nav {
				top:68px;
			}
		}
	}

	.main-container {
		.align-site {
			.sidebar {
				.sidebar-content {
					padding:15px 10px;
					.sidebar-toggle {
						top:13px;
						right:13px;
					}
					h2 {
						font-size:18px;
					}
				}
			}
		}
	}


	.post {
		&.post-big {
			.image {
				padding-bottom: 100%;
			}
		}
	}

	.featured-posts {
		.right {
			.post {
				width:100%;
			}
		}
	}

	.monthly-featured {
		h2 {
			font-size:18px;
		}
		.right {
			.post {
				width:100%;
			}
		}
	}

	.post-list {
		.post {
			width:100%;
		}
	}

	.media-corner {
		h2 {
			font-size:18px;
		}
		.container {
			.right {
				.post {
					width:100%;
				}
			}
		}
	}

	footer {
		.bottom {
			.right {
				img {
					max-height:40px;
					margin-right: 10px;
				}
			}
		}
	}
}
